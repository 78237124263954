import React , {useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import img1 from '../assets/images/box-item/icon1-recont-post.jpg'
import img2 from '../assets/images/box-item/icon2-recont-post.jpg'
import img3 from '../assets/images/box-item/icon3-recont-post.jpg'
import img4 from '../assets/images/box-item/icon4-recont-post.jpg'
import imgblog1 from '../assets/images/blog/thumb-7.jpg'
import imgblog2 from '../assets/images/blog/thumb-6.jpg'
import imgblog3 from '../assets/images/blog/thumb1_details.jpg'
import imgblog4 from '../assets/images/blog/thumb4_details.jpg'
import { Accordion } from 'react-bootstrap-accordion'


const BlogDetails = () => {
    const [data] = useState(
        [
            {   key: "0",
                show: "show",
                title: 'Kan jag köpa Bitcoin för 10 kr?',
                text: 'Ja, du kan köpa Bitcoin för 10 kr, och till och med mindre än 10 kr beroende på vilket utbyte du använder. Bitcoin kan delas upp i mycket små fraktioner och många investerare väljer att köpa det till en liten återkommande kurs som 5 kr per dag.'
            },
            {
                key: "1",
                title: 'Hur köper nybörjare Bitcoin?',
                text: 'Nybörjare kan köpa Bitcoin direkt genom att registrera sig för en kryptovalutabörs och använda deras omedelbara köpalternativ. Alternativt kan de köpa Bitcoin på en spotmarknad med lite forskning om hur handelsgränssnitt fungerar.'
            },
            {
                key: "2",
                title: 'Hur mycket Bitcoin bör en nybörjare köpa?',
                text: 'Köp bara så mycket Bitcoin som du är bekväm med och har råd med. Väg fördelarna med att köpa den och de potentiella riskerna.'
            },
            {
                key: "3",
                title: 'Vad är det säkraste sättet att köpa Bitcoin?',
                text: 'Det säkraste sättet att köpa Bitcoin är genom en välrenommerad kryptovalutabörs. Finder har'
            },
            {
                key: "4",
                title: 'Vad är det bästa sättet att köpa Bitcoin?',
                text: 'Det bästa sättet att köpa Bitcoin är att finansiera ett utbyteskonto och använda spotmarknaden. Detta resulterar i de lägsta avgifterna och mest BTC för dina pengar jämfört med omedelbara köp.'
            }
        ]
    )
    const [dataRecent] = useState(
        [
            {
                img: img1,
                title: 'Binance',
                text : 'Banköverföring (ACH)',
                time: '371 kryptovaluta',
                linkTo: 'https://www.binance.com/'
            },
            {
                img: img2,
                title: 'FTX',
                text : 'Kreditkort, Elektronisk',
                time: '263 kryptovaluta',
                linkTo: 'https://ftx.com/'
            },
            {
                img: img3,
                title: 'Bitpanda',
                text : 'Kreditkort, kryptovaluta',
                time: '82 kryptovaluta',
                linkTo: 'https://www.bitpanda.com/'
            },
            {
                img: img4,
                title: 'Bit.com',
                text : 'USD',
                time: '29 kryptovaluta',
                linkTo: 'https://www.bit.com/'
            },

        ]
    )
    const [dataTags] = useState(
        [
            {
                name: 'Bitcoin'
            },
            {
                name: 'Kryptobörser'
            },
            {
                name: 'Köp Bitcoin'
            },
            {
                name: 'Börser'
            },
            {
                name: 'Plånbok'
            },
            {
                name: 'Kryptocurrency'
            },
            {
                name: 'kryptovalutor'
            },
            {
                name: 'kryptovalutabörser'
            },
            {
                name: 'Krypto'
            },
        ]
    )
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Kryptobörser</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Kryptobörser</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-section post-details">
                <div className="themesflat-container">
                    <div className="wrap-flex-box style">
                        <div className="post">
                            <div className="inner-content">
                                <h2 className="title-post">Hur man köper Bitcoin (BTC) i Sverige</h2>
                                <p className="mg-bt-16">Lär dig hur du enkelt köper Bitcoin (BTC) genom att följa vår steg-för-steg-guide och jämföra 10+ kryptobörser.</p>

                                <div className="box-disclaimer mg-bt-31">
                                    <p><b>Friskrivningsklausul:</b> Denna information ska inte tolkas som ett stöd för kryptovaluta eller någon specifik leverantör, tjänst eller erbjudande. Det är ingen rekommendation att handla.</p>
                                </div>

                                <h3 className="heading mg-bt-16">Hur Man Köper Bitcoin</h3>

                                <p className="mg-bt-24">För att köpa Bitcoin behöver du bara tillgång till en av de många plattformarna som säljer den.
                                Dessa plattformar kallas kryptovalutabörser. Om du precis har börjat med Bitcoin, måste du ta reda på hur du konverterar din statliga
                                valuta (fiat) till Bitcoin med någon av dessa börser. Vi har kokat ner processen
                                att köpa Bitcoin i fyra steg. Oavsett vilket utbyte du använder kommer dessa 4 steg att vara ungefär desamma.
                                </p>
                                <p className="mg-bt-24">Oroa dig inte – även om du kanske tycker att Bitcoin är komplext, är det enkelt att köpa det och det tar cirka 30 minuter av din dag.
                                </p>

                                <div className="image">
                                    <img src={imgblog1} alt="Crypto Centralen" />
                                </div>

                                <h4 className="title-widget mg-bt-16 mg-t-40">Steg 1. Välj var du vill köpa</h4>

                                <p className="mg-bt-24">En kryptovalutabörs är en webbplats som låter dig köpa och sälja kryptovalutor. De fungerar på samma sätt som aktiehandelsplattformar. Utbyten av kryptovaluta skiljer sig åt beroende på avgifter, säkerhet, betalningsmetoder och funktioner,
                                så använd vår tabell för att avgöra vilken som passar dig.
                                Som nybörjare behöver du inte ett stort utbud av funktioner, och du kan alltid registrera dig på andra utbyten senare.
                                </p>

                                <p className="mg-bt-24">Några av de mest populära börserna
                                som accepterar SEK är FTX Cryptocurrency Exchange, Bitpanda Cryptocurrency
                                Exchange och Bit.com Cryptocurrency Exchange. Alla dessa utbyten anses vara relativt
                                nybörjarvänliga och erbjuder insättningsmetoder i en mängd olika metoder.
                                </p>

                                <p className="mg-bt-24">Du kan se en lista över börser i tabellen,
                                som du kan använda för att jämföra olika funktioner som betalningsmetoder som stöds, säkerhet, avgifter och antalet olika kryptovalutor som de erbjuder.
                                </p>

                                <p className="mg-bt-24">När du har valt ett utbyte måste du gå till dess hemsida och skapa ett konto. Du kan göra detta genom att välja knappen bredvid börsens namn i tabellen.
                                </p>

                                <p className="mg-bt-24">För att hjälpa dig välja ett utbyte, överväg följande:
                                </p>

                                <ul>
                                    <li><b>Avgifter:</b> Köp och försäljning av kryptotillgångar medför avgifter som kan öka med tiden.
                                        Att betala med kreditkort tenderar att vara det dyraste alternativet, eftersom det i många fall anses vara ett kontantförskott.
                                        Håll utkik efter byten med gratis betalningsmetoder som banköverföring.
                                        Leta efter börser med låga handelsavgifter, noll insättningsavgifter för fiat och krypto och billiga eller täckta uttag.</li>
                                    <li><b>Mynt:</b> Du vet att du vill ha lite BTC, men finns det andra mynt och polletter du vill köpa senare? Om du planerar att diversifiera din portfölj, leta efter ett utbyte med dina andra tillgångar av intresse.
                                        Om du bara köper Bitcoin, välj en börs som ger dig de lägsta avgifterna och bästa säkerheten.
                                        Om du planerar att handla med andra kryptovalutor, hitta en plattform som passar dessa behov.</li>
                                    <li><b>Plats:</b> Reglerade kryptovalutabörser måste följa lokala lagar och förordningar.
                                        Vissa utbyten är inte tillgängliga i vissa länder eller stater.
                                        Var försiktig med börser i utländska skatteparadis med svaga konsumentlagar och oreglerade börser.</li>
                                </ul>

                                <h4 className="title-widget mg-bt-16 mg-t-40">Steg 2: Skapa ett konto</h4>

                                <p className="mg-bt-24">När du har valt ett utbyte från tabellen kan du registrera dig för ett konto genom att
                                välja knappen "Gå till webbplats". Detta tar dig till sidan för att skapa konto, där du kan fylla i dina uppgifter, med början med ditt namn och din e-postadress.
                                </p>

                                <p className="mg-bt-24">Du kommer då att behöva verifiera din identitet och adress, vilket är en del av en process som kallas Know Your Customer (KYC),
                                vilket är ett lagkrav för de flesta börser i Sverige. För att göra detta behöver du fotolegitimation samt en kopia av ett färskt kontoutdrag eller elräkning som har din adress på.
                                </p>

                                <p className="mg-bt-24">KYC godkänns vanligtvis omedelbart, även om du i vissa fall måste vänta några
                                dagar innan ditt konto verifieras. När ditt konto har godkänts måste du välja en betalningsmetod för att sätta in pengar på ditt konto innan du kan göra några köp.
                                </p>

                                <h4 className="title-widget mg-bt-24 mg-t-40">Steg 3: Sätt in pengar på ditt konto</h4>

                                <div className="image">
                                    <img src={imgblog4} alt="Crypto Centralen" />
                                </div>

                                <p className="mg-bt-24 mg-t-40">För att köpa Bitcoin (BTC) måste du först sätta in pengar på ditt växlingskonto eller länka ett kredit- eller betalkort.
                                </p>

                                <p className="mg-bt-24"><b>Sätt in pengar:</b> Det billigaste sättet att köpa Bitcoin är vanligtvis genom att sätta in pengar från ditt bankkonto
                                till en börs och sedan använda dem för inköp. Det kan dock sluta med att du väntar några dagar på att pengarna kommer fram, beroende på bank och börs. När pengarna kommer fram kan du köpa Bitcoin.
                                </p>

                                <p className="mg-bt-24"><b>Köpa med kreditkort:</b> Att köpa Bitcoin med kreditkort kan vara ett bekvämt sätt att köpa Bitcoin, men det finns avvägningar. Till att börja med är avgifter vanligtvis dyrare än att sätta in pengar via en banköverföring. Kreditkort används främst för omedelbara köp, så du kan inte använda dem för att sätta in pengar på börsen. Om du bara har ett kreditkort tillgängligt kan avgifterna öka snabbt. Till exempel kommer kreditkortsleverantörer vanligtvis att debitera dig en kontant förskottsavgift som kan vara ganska kostsam. Beroende på din kreditkortsleverantör
                                och ditt land kommer vissa leverantörer inte att låta dig köpa kryptovaluta. Kolla in Finders guide om hur du köper Bitcoin med ett kreditkort för att ta reda på detaljerna som fungerar bäst för dig.
                                </p>

                                <p className="mg-bt-24"><b>Att köpa med betalkort:</b> Att köpa med betalkort är
                                ungefär som att använda ett kreditkort, men med mycket bättre avgifter. Av de 3 alternativen för att finansiera och köpa Bitcoin ger ett betalkort dig anständiga avgifter och nästan omedelbar insättning av pengar eller Bitcoin.
                                </p>

                                <p className="mg-bt-24">Stressa inte för mycket över vilket alternativ du väljer,
                                 eftersom du alltid kan ändra det senare. Men om du planerar att göra ett stort köp, är det värt att beräkna avgifterna för varje metod för att avgöra det bästa värdet.
                                </p>

                                <h4 className="title-widget mg-bt-16 mg-t-40">Steg 4: Köp Bitcoin</h4>

                                <p className="mg-bt-24">Först till kvarn – du behöver inte köpa en hel Bitcoin.
                                De flesta byten låter dig köpa så lite som för några svenska kronor,
                                vanligtvis ännu mindre. Skriv bara in hur mycket du vill spendera i SEK och låt bytet räkna ut resten.
                                </p>

                                <p className="mg-bt-24">För det andra, kom ihåg att vissa börser bara har ett sätt att köpa Bitcoin, medan andra erbjuder flera sätt att köpa. De 2 vanligaste metoderna i Sverige är direktköp och spotmarknaden.
                                </p>

                                <h4 className="title-widget mg-bt-16 mg-t-40">Köp omedelbart</h4>

                                <p className="mg-bt-24">Ett omedelbart köp görs vanligtvis med ett kreditkort, men kan också göras med fiat eller krypto redan på ditt utbyteskonto. Direktköp tenderar att ha de högsta avgifterna och är till marknadspris, vilket innebär att du sannolikt betalar mer än du skulle göra om du använde spotmarknaden. Om du använder ett kreditkort tillkommer ytterligare avgifter. Med omedelbara köp betalar du för bekvämlighet och snabbhet.
                                I allmänhet väljer du en tillgång att köpa och ett belopp att spendera. Du får sedan en förhandsvisning av transaktionen och efter att ha kontrollerat detaljerna slutför du köpet.
                                </p>

                                <h4 className="title-widget mg-bt-16 mg-t-40">Spotmarknad</h4>

                                <p className="mg-bt-24">Spotmarknaden finns ofta under rubriken "Handel" eller "Spot". Till skillnad från ett omedelbart köp tillåter spotmarknaden handlare att ställa in önskade parametrar för ett köp eller försäljning. Det finns flera typer av affärer som du kan göra på en spotmarknadssida. Du kan göra en marknadsorder, som är som ett omedelbart köp/sälj men har lägre avgifter. Du kan också göra en limitorder.
                                Detta är den andra vanligaste ordertypen, där du väljer ett önskat pris att köpa eller sälja. Limitorder har lägre avgifter än marknadsorder. Det kan också finnas andra handelsalternativ, beroende på börsen.
                                </p>



                                <div className="image">
                                    <img src={imgblog2} alt="Crypto Centralen" />
                                </div>

                                <h4 className="title-widget mg-bt-16 mg-t-40">Efter att du har köpt Bitcoin</h4>

                                <p className="mg-bt-24">När du väl har förvärvat Bitcoin, antingen genom ett omedelbart köp eller spotmarknaden, har du två alternativ. Det första alternativet är att lämna det på börsen för att snabbt kunna sälja eller handla det.
                                Det andra alternativet är att skicka din Bitcoin till en extern plånbok där du håller nycklarna. Detta är mer komplicerat, men ett säkrare övergripande val.
                                </p>

                                <h5 className="title-widget mg-bt-16 mg-t-40">Skicka din Bitcoin till en plånbok</h5>

                                <p className="mg-bt-24">Att lära sig hur man använder en kryptovaluta-plånbok tar lite tid och ansträngning. Men det finns flera fördelar med att flytta dina mynt från ett utbyte.
                                </p>

                                <ul>
                                    <li><b>Inte dina nycklar, inte dina mynt:</b> Ett mantra som upprepas av kryptovalutaentusiaster är "Inte dina nycklar, inte dina mynt." Om inte din Bitcoin ligger
                                        i en plånbok som du äger de privata nycklarna till, så har du inte kontroll över den.
                                        Många anser att det är dålig praxis att hålla mynt på ett utbyte eftersom det betyder att du litar på dem till en tredje part.</li>
                                    <li><b>Hacking:</b> Utbyten är främsta mål för hackare, som antingen kan hacka själva börsen eller lura användare att lämna över sin kontoinformation genom tekniker som kallas nätfiske.</li>
                                    <li><b>Säkerhet:</b> Bitcoin och cryptocurrency plånböcker varierar mycket i sina funktioner och säkerhet.
                                        För den säkraste upplevelsen, överväg att köpa en hårdvaruplånbok,
                                        som är en liten USB-enhet som håller dina privata nycklar offline hela tiden, vilket lägger till ett extra lager av säkerhet.</li>
                                    <li><b>Verktyg:</b> Om du planerar att använda din Bitcoin för transaktioner, dagliga utgifter eller decentraliserad finansiering (DeFi), kommer det att vara mycket bekvämare att lagra den i en plånbok snarare än ett utbyte.</li>
                                </ul>

                                <h5 className="title-widget mg-bt-16 mg-t-40">Håll din Bitcoin på ett utbyte</h5>

                                <p className="mg-bt-24">Utbyten har kommit långt sedan Bitcoins tidiga dagar. Många av de stora aktörerna använder nu avancerad säkerhetspraxis, hårdvara och utbildning för att skydda användarnas pengar.
                                Flera börser försäkrar nu även användarmedel upp till ett visst värde.
                                Förutom säkerhet finns det ytterligare skäl att tänka på när du lämnar Bitcoin på en börs.
                                </p>

                                <ul>
                                    <li><b>Bekvämlighet:</b> Att lämna din Bitcoin på samma börs som du köpte den på är bekvämt av
                                        flera skäl. Du behöver bara komma ihåg ett enda konto och lösenord och kan enkelt
                                        fylla på din portfölj som du vill, och det låter dig hantera all din kryptorelaterade ekonomi på ett ställe.</li>
                                    <li><b>Casual investerare:</b> Om du är en tillfällig investerare och
                                        mer intresserad av att spekulera i priset på Bitcoin än att dra nytta av dess användbarhet,
                                        kan det vara det enklaste sättet för dig att hantera din investering att hålla din Bitcoin på en börs.</li>
                                    <li><b>Frekvent handlare:</b> Om du planerar att handla med Bitcoin ofta, är det enklaste
                                        alternativet att lämna det på börsen. Men du kan alltid använda en decentraliserad börs,
                                        vilket är ett sätt att handla direkt från din personliga kryptovaluta-plånbok.</li>
                                    <li><b>Security:</b> Every cryptocurrency exchange is different, and so is its security. Some exchanges share their security practices publicly,
                                        while others prefer to keep them confidential. If you plan to keep your coins on an exchange, make sure to do your own research.
                                        Some features you want to look for include the following:

                                        <ul>
                                            <li><b>Bekvämlighet:</b> Att lämna din Bitcoin på samma börs som du köpte den på är bekvämt av
                                                flera skäl. Du behöver bara komma ihåg ett enda konto och lösenord och kan enkelt
                                                fylla på din portfölj som du vill, och det låter dig hantera all din kryptorelaterade ekonomi på ett ställe.</li>
                                            <li><b>Casual investerare:</b> Om du är en tillfällig investerare och
                                                mer intresserad av att spekulera i priset på Bitcoin än att dra nytta av dess användbarhet,
                                                kan det vara det enklaste sättet för dig att hantera din investering att hålla din Bitcoin på en börs.</li>
                                            <li><b>Frekvent handlare:</b> Om du planerar att handla med Bitcoin ofta, är det enklaste
                                                alternativet att lämna det på börsen. Men du kan alltid använda en decentraliserad börs,
                                                vilket är ett sätt att handla direkt från din personliga kryptovaluta-plånbok.</li>
                                            <li><b>Security:</b> Every cryptocurrency exchange is different, and so is its security. Some exchanges share their security practices publicly,
                                                while others prefer to keep them confidential. If you plan to keep your coins on an exchange, make sure to do your own research.
                                                Some features you want to look for include the following:
                                                </li>
                                        </ul>

                                    </li>
                                    <li><b>Försäkring:</b> Det finns nu en mängd olika tjänster som kan tillhandahålla försäkring till kryptobörser för deras tillgångar.
                                        Även om det sällan finns täckning om ditt konto äventyras på din sida, om börsen hackas täcker försäkringen berörda användare. Leta efter byten med någon form av försäkring.</li>
                                    <li><b>Utlåning:</b> Vissa börser kommer att erbjuda dig chansen att tjäna avkastning på dina Bitcoin-innehav,
                                        precis som en bank betalar ränta på kontantinsättningar. Detta kan vara ett bra sätt att växa din portfölj utan att handla.</li>
                                </ul>

                                <h4 className="title-widget mg-bt-16 mg-t-40">Riskerna med att köpa Bitcoin</h4>
                                <p className="mg-bt-24">Du skulle inte investera i krypto utan att göra din forskning först, så se till att du förstår några viktiga fakta om Bitcoin innan du köper:
                                </p>

                                <div className="image">
                                    <img src={imgblog3} alt="Crypto Centralen" />
                                </div>

                                <ul className='mg-t-40'>
                                    <li><b>Prisvolatilitet:</b> Bitcoins pris är till stor del baserat på spekulation, vilket innebär att det kan stiga eller falla på kort tid.
                                        Det är inte ovanligt att Bitcoin förlorar mer än 10% av sitt värde på en enda dag.
                                        Ett vanligt råd i investeringskretsar som är tillämpligt här är "investera bara så mycket du har råd att förlora."</li>
                                    <li><b>Säkerhet:</b> Det är viktigt att förstå risken med att lämna dina mynt på en börs jämfört med att använda en plånbok.
                                         Om du vill använda en plånbok måste du också lära dig hur offentliga och privata nycklar fungerar.</li>
                                    <li><b>Transaktioner kan inte ångras.</b> När du väl har skickat in en transaktion till Bitcoin-nätverket kan den inte avbrytas eller ångras.
                                        Så dubbelkolla mottagningsadressen innan du skickar en Bitcoin-betalning.
                                        Det finns ingen bank som kan återbetala förlorade pengar som skickats till fel adress.</li>
                                    <li><b>Bitcoin är inte anonymt.</b> Det finns en utbredd missuppfattning att alla Bitcoin-transaktioner är anonyma.
                                        Detta är inte fallet eftersom din allmänna adress och detaljerna om dina transaktioner är synliga för alla. Din identitet kan kopplas till dina Bitcoin-adresser
                                        eftersom de flesta Bitcoin köps via börser där du har gett dem din identitet genom deras KYC-process.
                                        Om anonyma transaktioner är en viktig funktion för dig, sök efter ett sekretessfokuserat mynt, som Monero.</li>
                                    <li><b>Om du har Bitcoin som en investering</b> kommer du att beskattas för eventuella kapitalvinster du gör när den säljs. Skatter gäller även för Bitcoin-brytning,
                                        professionella Bitcoin-handlare och i en rad andra situationer.
                                        Så se till att du är fullt medveten om vad du behöver för att rapportera ditt styrande organ. Du kan också kolla in Finders kryptoskatteguide för mer information.</li>
                                    <li><b>Förordning.</b> Bitcoins reglerande miljö håller fortfarande på att bildas. Tjurmarknaden 2021 fick många tillsynsmyndigheter och finansiella organ runt om i världen att ompröva hur de behandlar Bitcoin. När det gäller att köpa, beskatta eller direkt förbjuda det, görs fortfarande förändringar.
                                        Även om du bor i en nation som har en stödjande regulatorisk miljö för Bitcoin och digitala tillgångar, är det fortfarande viktigt att notera att händelser i andra nationer har potential att påverka den gränslösa valutan.</li>
                                </ul>

                                <h4 className="title-widget mg-bt-16 mg-t-40">Vanliga frågor</h4>

                                <div className="col-md-12 mg-t-40 mg-bt-40">
                                    <div className="flat-accordion2">
                                        {
                                            data.map((item,index) => (
                                                <Accordion key={index} title={item.title} >
                                                    <p>{item.text}</p>
                                                </Accordion>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="box-disclaimer mg-bt-31">
                                    <p><b>Ansvarsfriskrivning:</b> Kryptovalutor är spekulativa, komplexa och innebär betydande risker – de är mycket volatila och känsliga för sekundär aktivitet.
                                        Resultatet är oförutsägbart och tidigare resultat är ingen garanti för framtida resultat. Tänk på dina egna omständigheter och få dina egna råd innan du förlitar dig på denna information. Du bör också verifiera vilken produkt eller tjänst som helst (inklusive dess juridiska status och relevanta regulatoriska krav)
                                        och konsultera relevanta tillsynsmyndigheters webbplatser innan du fattar något beslut.
                                        Finder, eller författaren, kan ha innehav i de diskuterade kryptovalutorna.</p>
                                </div>



                            </div>
                        </div>
                        <div className="side-bar details">
                            <div className="widget widget-recent-post mg-bt-43">
                                <h3 className="title-widget mg-bt-23">Börser</h3>
                                <ul>
                                    {
                                        dataRecent.map((item,index) => (
                                            <li key={index} className="box-recent-post">
                                                <div className="box-feature"><span><img src={item.img} alt="Crypto Centralen" /></span></div>
                                                <div className="box-content">
                                                    <p className="title-recent-post">{item.title}</p>
                                                    <span><span className="sub-recent-post">{item.text}</span><span className="day-recent-post">{item.time}</span></span>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="widget widget-tag style-1">
                                <h3 className="title-widget mg-bt-23">Populär Tag</h3>
                                <ul>
                                    {
                                        dataTags.map((item,index) => (
                                            <li key={index}><Link to="#" className="box-widget-tag">{item.name}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default BlogDetails;
