const menus = [
    {
        id: 1,
        name: 'Introduction',
        links: '/',
    },
    {
        id: 2,
        name: 'Kryptobörser',
        links: '/crypto-exchanges',
    }

]

export default menus;