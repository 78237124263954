import React from 'react';
import icon3 from '../../assets/images/icon/Wallet.png'
import icon5 from '../../assets/images/icon/earn.svg'
import icon2 from '../../assets/images/icon/buy.svg'
import icon4 from '../../assets/images/icon/safe.svg'
import icon1 from '../../assets/images/icon/Bookmark.png'
import icon6 from '../../assets/images/icon/hold.svg'
import imgblog2 from '../../assets/images/blog/bill.png'
import imgblog3 from '../../assets/images/blog/shild.png'
import imgblog4 from '../../assets/images/blog/money-safe.png'






const Create = () => {
    const data = [
        {
            title: "Hur fungerar en kryptovaluta?",
            description: "Låt oss lätta försiktigt, eller hur? Om obligationer är den traditionella gentlemannen i hög hatt och aktier",
            icon : icon1,
            colorbg : "icon-color1",
            toId : "how-it-works"
        },
        {
            title: "Hur köper jag kryptovaluta?",
            description: "Den mest populära metoden är via kryptoutbyte, så du kommer förmodligen att vilja hitta en.",
            icon : icon2,
            colorbg : "icon-color2",
            toId : "how-to-buy"
        },
        {
            title: "Starta din kryptoresa med Ledger",
            description: "Om du är redo att börja din kryptoresa erbjuder Ledger ett säkert och enkelt sätt att göra just det.",
            icon : icon3,
            colorbg : "icon-color3",
            toId : "start"
        },
        {
            title: "Är det säkert att köpa kryptovaluta?",
            description: "Tyvärr är kryptovalutamarknaden ökänd för bedrägerier. En anledning till",
            icon : icon4,
            colorbg : "icon-color4",
            toId : "secure"
        },
        {
            title: "Kan jag tjäna pengar på kryptovaluta?",
            description: "Kryptovalutor har bara funnits i stort sett i drygt ett decennium.",
            icon : icon5,
            colorbg : "icon-color5",
            toId : "earn"
        },
        {
            title: "Att HODL eller inte till HODL?",
            description: "Vid det här laget känner du dig förmodligen ganska ikapp. Du vet vad kryptovalutor är",
            icon : icon6,
            colorbg : "icon-color6",
            toId : "hold"
        }
    ]
    return (
        <section className="tf-box-icon create style1 tf-section">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12 intro">
                        <div className="heading-live-auctions">
                            <h2 className="tf-title pb-17 main-title">
                            En enkel introduktion till kryptovalutor</h2>
                        </div>
                        <p className="mg-bt-24">Välkommen till vår guide till kryptovalutor.
                        Denna guide kommer att beskriva de saker du bör tänka på när du går in i en värld av kryptovalutainvesteringar – inklusive hur du köper och lagrar din kryptovaluta, även kallad krypto förkortat.
                        </p>
                        <p className="mg-bt-24">Vi börjar med att förklara vad en kryptovaluta är samt hur de fungerar.</p>
                    </div>
                    {
                        data.map((item , index) => (
                            <CreateItem key={index} item={item} />
                        ))
                    }
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="inner-post pad-t-90"  id="how-it-works">
                            <h2 className="heading mg-bt-16 intro">1. Hur fungerar en kryptovaluta?</h2>
                            <p className="mg-bt-24">Låt oss lätta försiktigt, eller hur? Om obligationer är den traditionella gentlemannen i
                            hög hatt och aktier är den påträngande högljudda munnen med en telefon mot örat, är kryptovalutor barnet i
                            en luvtröja som sitter längst bak i klassen och kodar och klotterar omslag av heavy metal-skivor.
                            Crypto har funnits i över 10 år, men det slog igenom till det vanliga medvetandet först 2017 när priserna steg.
                            </p>
                            <p className="mg-bt-24">Kryptovalutor underlättar betalningar – eller annat utbyte av information – mellan människor,
                            utan tillsyn av ett centralt organ som en regering eller en bank.
                            Många människor hade försökt skapa digitala kontanter tidigare, men alla hade misslyckats – tills bitcoin lanserades 2008.
                            </p>
                            <h3 className="heading mg-bt-16 sub-intro">Vad är speciellt med bitcoin?</h3>
                            <p className="mg-bt-24">Bitcoin körs över ett distribuerat nätverk av datorer – till skillnad från en centraliserad uppsättning servrar,
                                som de som används av traditionella banker. Alla transaktioner registreras på en oföränderlig reskontra
                                i princip en lång lista som aldrig kan ändras, som går under det tekniska namnet "blockchain".
                            </p>
                            <p className="mg-bt-24">Medan bitcoin har tagit på sig digitala pengar,
                            tillämpar många andra kryptoprojekt blockchain-teknik på olika områden:
                            allt från sociala medier till fillagring har blockchain-baserade projekt som försöker störa etablerade industrier.
                            </p>
                            <p className="mg-bt-24">Du kommer också att höra ordet token i blockchain-världen.
                            Det här är inte de tokens du kan använda på en spelhall.
                            Snarare är dessa tokens digitala tillgångar som finns i blockkedjan och kan fungera som allt från en valuta till en digital vara eller tjänst.
                            </p>
                            <h3 className="heading mg-bt-16 sub-intro">Varför har kryptovalutor något värde?</h3>
                            <p className="mg-bt-24">Allt handlar om utbud – som för flera stora kryptor är medvetet begränsat – och efterfrågan. Om en token kan locka användare kommer den att stiga i värde. Så om det finns en efterfrågan på att använda bitcoin för att överföra pengar, kommer dess värde sannolikt att gå upp. Digitala tokens kan också knytas till – och härleda sitt värde från – verkliga tillgångar som fastigheter eller ett fat olja.
                            De kryptotransaktioner som har fått mest uppmärksamhet i media har dock varit de mest besynnerliga – som när någon betalade $170 000 för en token som representerade en digital kattunge.
                            </p>

                            <h3 className="heading mg-bt-16 sub-intro">Varför skulle jag bry mig?</h3>
                            <p className="mg-bt-24">Efter den här introduktionen kommer du naturligtvis att fråga dig själv om kryptovalutor är en värd investering. Och även om ditt svar kommer att bero på dina investeringsmål och riskaptit, är marknaden fortfarande i ett tidigt skede – vilket innebär att den mestadels är oreglerad – och priserna kan svänga vilt.
                            Men kryptovalutaentusiaster pekar på dess potential som en "inflationssäkring"
                            och en valuta i sig själv, eftersom digitala valutor snart kan stiga i värde totalt sett
                            </p>
                            <p className="mg-bt-24">Titta på det här utrymmet – och resten av den här serien – för att se om du håller med.
                            </p>
                        </div>

                        <div className="inner-post pad-t-90" id="how-to-buy">
                            <h2 className="heading mg-bt-16 intro">2. Hur köper jag kryptovaluta?</h2>
                            <div className="image mg-t-24">
                                <img src={imgblog2} alt="Crypto Centralen" />
                            </div>
                            <div className="mg-t-24">
                                <p className="mg-bt-24">Den mest populära metoden är via kryptoutbyte,
                                så du kommer förmodligen att vilja hitta en. Dessa låter dig köpa kryptovalutor med dina pund,
                                euro eller dollar (a.k.a. din fiatvaluta) – och låter dig handla mellan olika typer av krypto.
                                </p>
                                <p className="mg-bt-24">Kryptovalutautbyten finns i alla former och storlekar,
                                så att välja en är inte nödvändigtvis lätt. Du vill se till att din valda börs tillåter dig att handla med kryptotokens du är intresserad
                                av och låter dig konvertera till eller från fiat-valutor. Du bör också titta
                                på börsens transaktionsavgifter, om tillämpligt, och se till att du är bekväm med att betala dem.
                                </p>
                                <h3 className="heading mg-bt-16 sub-intro">Vad mer behöver jag veta?</h3>
                                <p className="mg-bt-24">Det viktigaste att veta är att ägande av krypto handlar om att äga offentliga och privata nycklar. En offentlig nyckel är som en e-postadress: du delar den för att skicka och ta emot pengar
                                (eller e-postmeddelanden). Och – precis som du aldrig skulle dela ditt e-postlösenord – bör du
                                aldrig dela din privata nyckel: det är det som gör att du faktiskt kan komma åt dina pengar.
                                </p>
                                <p className="mg-bt-24">Att lämna dina pengar på en kryptobörs är riskabelt. Du anförtror effektivt din privata nyckel till utbytet, och om något går snett, kommer du att kyssa dem adjö (det är den oreglerade delen som spelar in). Ur säkerhetssynpunkt är det dock bättre att överföra dina pengar till en "plånbok" - där du kan lagra och hantera din krypto. Med andra ord, en plånbok låter dig ta den privata nyckeln i dina egna händer för förvaring. Plånböcker finns också i
                                olika former och storlekar: vissa kommer att ha stöd för bara en
                                kryptovaluta medan andra kommer att vara kompatibla med flera, tillsammans med en mängd andra tjänster.
                                </p>
                            </div>
                        </div>

                        <div className="inner-post pad-t-90" id="start">
                            <h2 className="heading mg-bt-16 intro">3. Starta din kryptoresa med Ledger</h2>
                            <div className="mg-t-24">
                                <p className="mg-bt-24">Om du är redo att börja din kryptoresa erbjuder Ledger ett säkert och enkelt sätt att göra just det.
                                </p>
                                <p className="mg-bt-24">Det finns inget bättre ställe att köpa,
                                sälja, hantera och byta på än Ledgers allt-i-ett-app. Kombinera den med
                                deras toppmoderna hårdvaruplånbok och du kommer att upptäcka fullständig kontroll och maximal säkerhet.
                                </p>
                                <p className="mg-bt-24">Besök Ledger's School of Block för att avmystifiera krypto ett steg i taget.
                                </p>
                            </div>
                        </div>

                        <div className="inner-post pad-t-90" id="secure">
                            <h2 className="heading mg-bt-16 intro">4. Är det säkert att köpa kryptovaluta?</h2>
                            <div className="image mg-t-24">
                                <img src={imgblog3} alt="Crypto Centralen" />
                            </div>
                            <div className="mg-t-24">
                                <p className="mg-bt-24">Tyvärr är kryptovalutamarknaden ökänd för bedrägerier. En anledning till det är "oföränderligheten" av blockchain-transaktioner: det går inte att vända dem.
                                </p>
                                <p className="mg-bt-24">Se upp för "social ingenjörskonst" och nätfiskebedrägerier. Dessa lurar dig att avslöja personlig information – vilket gör att en hackare kan stjäla din identitet – eller att ange ditt lösenord eller privata nyckel på till exempel
                                en falsk version av en riktig webbplats. För att vara skyddad,
                                verifiera att alla som kontaktar dig är legitima – och undvik utlovade returer som verkar för bra för att vara sanna.
                                </p>
                                <p className="mg-bt-24">Hackare kan också försöka bryta sig in i en börs och stjäla tokens som lagras där.
                                Det är en anledning till att du kanske vill överväga att ha dina tokens i en separat plånbok.
                                </p>
                                <h3 className="heading mg-bt-16 sub-intro">Plånböcker, säger du...</h3>
                                <p className="mg-bt-24">Kommer du ihåg de privata nycklarna till plånböcker vi
                                nämnde tidigare? Om den inte lagras säkert offline, kan någon som inte är du potentiellt använda din nyckel för att få tillgång till dina pengar.
                                </p>
                                <p className="mg-bt-24">Du bör också veta att två personer med en privat nyckel till samma plånbok har
                                samma anspråk på att kontrollera dessa pengar. Så om din nyckel hamnar i orätta händer på något sätt – online eller offline – är det ett problem.
                                Och eftersom krypton är decentraliserad, finns det ingen tredje parts auktoritet för att lösa tvister
                                eller upprätthålla ägande, vilket är anledningen till att det är så viktigt att se till att du är den enda som kan komma åt din privata nyckel.
                                </p>
                            </div>
                        </div>

                        <div className="inner-post pad-t-90" id="earn">
                            <h2 className="heading mg-bt-16 intro">5. Kan jag tjäna pengar på kryptovaluta?</h2>
                            <div className="mg-t-24">
                                <p className="mg-bt-24">Kryptovalutor har bara funnits i stort sett i drygt ett decennium. Så för investerare med långsiktiga framtidsutsikter
                                är det förmodligen för tidigt att säga på ett eller annat sätt. Men det finns en handfull sätt som vissa optimistiska investerare försöker tjäna på krypto.
                                </p>
                                <h3 className="heading mg-bt-16 sub-intro">Spekulation</h3>
                                <p className="mg-bt-24">Priser på kryptovalutor – liksom finansiella tillgångar – är en funktion av utbud och efterfrågan. Och flera investerare tror att i takt med att kryptovalutor blir mer och mer mainstream – det vill säga attraherar fler privata investerare (individer som du) och institutionella investerare (stora värdepappersföretag som Fidelity och Goldman Sachs) – kommer deras värde att stiga. Kryptoinvesterare som satsar på ett sådant resultat kallas spekulanter. De följer flitigt nyheter som tyder på ökad användning av kryptovalutor
                                och högprofilerade kryptobaserade projekt, delvis i hopp om att det uppmuntrar andra investerare att
                                köpa in – vilket driver upp värdet på deras insatser, allt annat lika.
                                </p>
                                <h3 className="heading mg-bt-16 sub-intro">Inflationssäkring</h3>
                                <p className="mg-bt-24">Optimistiska investerare (som också kan vara spekulanter) ser kryptovalutor som har potentialen att omdefiniera betalningar – genom att agera som en valuta, möjliggöra transaktioner
                                och lagra värde. Det är den potentiella rollen som en plats för att hålla kontanter
                                säkra som har gett krypto en plats i vissa aktiemarknadsinvesterares portföljer.
                                </p>
                                <p className="mg-bt-24">Deras argument, enkelt uttryckt, är att bitcoin och andra kryptovalutor
                                fungerar som "digitalt guld": deras värde borde stiga om priserna på varor och tjänster (a.k.a. inflationen) ökar avsevärt.
                                Det beror på att utbudet av kryptovalutor är begränsat av design (som fysiskt guld, om än av en slump), och kryptos ökade användbarhet jämfört med guld – eftersom det är lättare att köpa, överföra och lagra – borde betyda att det är mer värdefullt.
                                </p>
                                <p className="mg-bt-24">Kom ihåg: hög inflation minskar värdet på dina pengar eftersom de inte kan köpa lika mycket när priserna ökar.
                                Så att lägga pengarna i tillgångar (som guld eller krypto) vars värde kommer att stiga med inflationen hjälper teoretiskt sett det – och din "köpkraft" – att hålla jämna steg.
                                </p>
                                <h3 className="heading mg-bt-16 sub-intro">Decentraliserad ekonomi</h3>
                                <p className="mg-bt-24">Decentraliserad finans – a.k.a. DeFi – är en snabbväxande sektor inom kryptovalutaindustrin vars potential har gjort flera entusiaster upphetsade.
                                En del av kryptovalutans ursprungliga attraktion var dess decentraliserade karaktär,
                                och DeFi tar det ett steg längre genom att skapa finansiella instrument som kan gynnas.
                                </p>
                                <p className="mg-bt-24">De flesta DeFi-plattformar har formen av decentraliserade appar, kända som DApps. Dessa DApps använder en serie smarta kontrakt för att automatisera finansiella transaktioner – vilket gör dem snabbare, effektivare och ofta mer
                                 överkomliga än sina centraliserade motsvarigheter.
                                 På samma sätt, eftersom DApps styrs av datorkod – som till sin natur är neutral – finns det inga problem med partiskhet.
                                </p>
                                <p className="mg-bt-24">Mycket av intresset kring DeFi har att göra med att ge människor mer kontroll över sina pengar
                                och mer intressanta sätt att använda dem – samt att förbättra tillgängligheten för dem med mer blygsamma summor av rikedom och i avlägsna regioner som vanligtvis ignoreras av stora finansinstitutioner . Utlåningsplattformar, kryptovalutmynt vars värde är knutet till befintliga fiat-valutor och
                                decentraliserade börser och försäkringar är bara några av de sätt på vilka kryptohoppare tror att tekniken kan förändra
                                finansvärlden – och rättfärdiga sina investeringar.
                                </p>
                            </div>
                        </div>

                        <div className="inner-post pad-t-90" id="hold">
                            <h2 className="heading mg-bt-16 intro">6. Att HODL eller inte till HODL?</h2>
                            <div className="image mg-t-24">
                                <img src={imgblog4} alt="Crypto Centralen" />
                            </div>
                            <div className="mg-t-24">
                                <p className="mg-bt-24">Vid det här laget känner du dig förmodligen ganska ikapp. Du vet vad kryptovalutor är, hur man köper och lagrar dem på ett säkert sätt
                                samtidigt som man undviker de vanligaste fallgroparna och varför folk köper in sig på krypto i första hand. Det är en lika bra plats som någon annan att börja fatta välgrundade ekonomiska beslut i kryptouniversumet, även om vi säger det själva.
                                De återstående tre guiderna kommer att dyka in i några av dessa ämnen mer i detalj – men innan du går, ville vi lämna dig med några ytterligare tankar...
                                </p>
                                <h3 className="heading mg-bt-16 sub-intro">Hodling krypto</h3>
                                <p className="mg-bt-24">"Hodl" är ett vanligt ord i kryptovalutans universum - och även om vi vanligtvis inte är fans av jargong,
                                är detta värt att veta om. Du förstår, efter att ett inlägg i ett kryptoforum 2013 innehöll ett stavfel i ordet "håll" när man diskuterade en "köp och håll"
                                långsiktig investeringsstrategi, blev "hodl" den tunga termen för att hålla fast vid vad du köper kommer vad som kan, ignorera upp- och nedgångarna på marknaden.
                                </p>
                                <p className="mg-bt-24">Vissa investerare tror att den potentiella belöningen från hodling uppväger riskerna: om en liten
                                kryptoallokering i din portfölj går till noll har du (förhoppningsvis) inte förlorat mycket. Men om krypton tar fart som man hoppats kan det vara värt mycket, mycket mer.
                                </p>
                                <h3 className="heading mg-bt-16 sub-intro">Hur kan jag engagera mig?</h3>
                                <p className="mg-bt-24">Det finns några olika sätt att investera i kryptovaluta.
                                Det tillvägagångssätt som passar dig bäst kommer förmodligen att bero på om du
                                vill handla aktivt eller helt enkelt spåra kryptovalutors bredare uppgångar eller fall.
                                </p>
                                <p className="mg-bt-24">Skillnaden? Aktiva handlare köper och säljer regelbundet och försöker fånga dipparna och
                                topparna – precis som människor som spekulerar på vilken annan marknad som helst, från olja till apelsinjuice.
                                De kommer att försöka analysera projekt och kan i allmänhet ha god kunskap om den övergripande branschen.
                                Många kommer att använda tekniska analystekniker, där tidigare prisdata studeras för att hjälpa till att fatta beslut om framtiden.
                                </p>
                                <p className="mg-bt-24">Passiva investerare, å andra sidan, är helt nöjda med att bara köpa krypto och hålla i det i hopp om att det ska klättra.
                                Ett populärt sätt att göra det är via kryptovalutapaket eller indexfonder som spårar värdet på de mest populära krypton.
                                Men akta köparen: dessa fondavgifter kan vara höga, så kolla vad de är och se om du är bekväm med att betala dem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const CreateItem = props => (
    <div className='col-lg-3 col-md-6 col-12 mt-5'>
        <div className="sc-box-icon intro">
            <div className="image">
                <div className={`icon-create ${props.item.colorbg}`}>
                    <img src={props.item.icon} alt="" />
                </div>
            </div>
            <h3 className="heading sub-intro"><a href={`#${props.item.toId}`}>{props.item.title}</a></h3>
            <p className="content">{props.item.description}</p>
        </div>
        <div className="col-md-12 wrap-inner load-more readall">
            <a href={`#${props.item.toId}`}>Läs allt</a>
        </div>
    </div>
)

export default Create;
