import React from 'react';

const DarkMode = () => {
    const body = document.body
    // const lightTheme = "light"
    const darkTheme = "is_dark"

    body.classList.add(darkTheme)

    return (
        <></>
    );
}

export default DarkMode;
