import Home01 from "./Home01";
import BlogDetails from "./BlogDetails";



const routes = [
  { path: '/', component: <Home01 />},
  { path: '/crypto-exchanges', component: <BlogDetails />}
]

export default routes;