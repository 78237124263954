import img1 from '../images/slider/slide_1.png';
import imgbg1 from '../images/slider/bg_slide_1.png'


const heroSliderData = [
    {
        title_1: "Välkommen till",
        title_2: "Crypto Centralen",
        title_3: "en guide till kryptovalutor",
        description: "Vad är kryptovalutor? Hur de fungerar de? Vad handlar all hype om? Hur kan jag säkert köpa & sälja dem?",
        img: img1,
        imgbg: imgbg1,
        class:'left'
    }
]

export default heroSliderData;